import React, { createContext, useState, useContext, ReactNode } from 'react';
import { lightTheme, darkTheme } from '../config/themeconfig';
import { DefaultTheme } from '../config/frontendconfig';

// Define the shape of the context value
interface ThemeContextType {
    theme: string;
    themeColors: typeof lightTheme | typeof darkTheme;
    toggleTheme: () => void;
}

// Add a type for the props of ThemeProvider
type ThemeProviderProps = {
    children: ReactNode;
};

const ThemeContext = createContext<ThemeContextType>({} as ThemeContextType);

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
    const savedTheme = localStorage.getItem('theme');
    const [theme, setTheme] = useState(savedTheme ? savedTheme : DefaultTheme);

    const toggleTheme = () => {
        const savedTheme = theme === 'light' ? 'dark' : 'light';
        setTheme(savedTheme);
        try {
            localStorage.setItem('theme', savedTheme);
        } catch (error) {
            console.log('Error saving theme to local storage: ', error)
        }
        
    };

    const themeColors = theme === 'light' ? lightTheme : darkTheme;
    const layoutStyle = {
        '--header-background-color': themeColors.headerBackground,
        '--header-text-color': themeColors.headerTextColor,
        '--chat-background-image': themeColors.chatBackgroundImage,
        '--chat-background-color': themeColors.chatBackgroundColor,
        '--chat-title-color': themeColors.chatTitleColor,
        '--chat-icon-fill-color': themeColors.chatIconFillColor,
        '--chat-command-text-color': themeColors.chatCommandTextColor,
        '--chat-sidebar-background': themeColors.chatSidebarBackground,
        '--chat-sidebar-item': themeColors.chatSidebarItem,
        '--chat-sidebar-active-item': themeColors.chatSidebarActiveItem,
        '--chat-sidebar-item-hover': themeColors.chatSidebarItemHover,
        '--chat-sidebar-item-border': themeColors.chatSidebarItemBorder,
        '--chat-sidebar-button-background': themeColors.chatSidebarButtonBackground,
        '--chat-sidebar-button-text-color': themeColors.chatSidebarButtonTextColor,
        '--chat-sidebar-button-hover-background': themeColors.chatSidebarButtonHoverBackground,
        '--scrollbar-thumb': themeColors.scrollbarThumb,
        '--scrollbar-track': themeColors.scrollbarTrack,
        '--question-input-background-color': themeColors.questionInputBackgroundColor,
        '--question-input-placeholder-text-color': themeColors.questionInputPlaceholderTextColor,
        '--answer-text-color': themeColors.answerTextColor,
        '--answer-background-color': themeColors.answerBackgroundColor,
        '--user-message-background-color': themeColors.userMessageBackgroundColor,
        '--user-message-text-color': themeColors.userMessageTextColor,
        '--chat-sidebar-button-border-color': themeColors.chatSideBarButtonBorderColor,
        '--citation-label-color': themeColors.citationLabelColor,
        '--edit-icon-button-background-color': themeColors.editIconButtonBackgroundColor,
        '--edit-icon-button-fill-color': themeColors.editIconButtonFillColor,
        '--citation-link-text-color': themeColors.citationLinkTextColor,
        '--citation-link-background-color': themeColors.citationLinkBackgroundColor,
        '--citation-sup-background-color': themeColors.citationSupBackgroundColor,
        '--save-button-background-color': themeColors.saveButtonBackgroundColor,
        '--save-button-border-color': themeColors.saveButtonBorderColor,
        '--cancel-button-background-color': themeColors.cancelButtonBackgroundColor,
        '--cancel-button-border-color': themeColors.cancelButtonBorderColor,
        '--cancel-button-text-color': themeColors.cancelButtonTextColor,
        '--cancel-button-hover-color': themeColors.cancelButtonHoverColor,
        '--file-upload-panel-background-color': themeColors.fileUploadPanelBackgroundColor,
        '--file-upload-panel-text-color': themeColors.fileUploadPanelTextColor,
        '--file-upload-panel-title-color': themeColors.fileUploadPanelTitleColor,
        '--dropzone-text-color': themeColors.dropzoneTextColor,
        '--dropzone-border-color': themeColors.dropzoneBorderColor,
        '--dropzone-hover-color': themeColors.dropzoneHoverColor,
        '--close-button-background-color': themeColors.closeButtonBackgroundColor,
        '--close-button-text-color': themeColors.closeButtonTextColor,
        '--disclaimer-text-color': themeColors.disclaimerTextColor,
        '--disclaimer-background-color': themeColors.disclaimerBackgroundColor,
        '--command-background-color': themeColors.commandBackgroundColor,
        '--prompt-guidance-background-color': themeColors.promptGuidanceBackgroundColor,
        '--ppl-disclaimer-background-color': themeColors.pplDisclaimerBackgroundColor,
        '--example-prompts-background-color': themeColors.examplePromptsBackgroundColor,
        '--example-prompts-card-color': themeColors.examplePromptsCardColor,
        '--example-prompts-border-color': themeColors.examplePromptsBorderColor,
        '--example-prompts-title-color': themeColors.examplePromptsTitleColor,
        '--example-prompts-text-color': themeColors.examplePromptsTextColor,
        '--example-prompts-dismiss-color': themeColors.examplePromptsDismissColor,
        '--history-item-menu-text-color': themeColors.historyItemMenuTextColor,
        '--history-item-menu-background-color': themeColors.historyItemMenuBackgroundColor,
        '--history-item-menu-hover-color': themeColors.historyItemMenuHoverColor,
        '--history-item-menu-icon-color': themeColors.historyItemMenuIconColor,
        '--history-item-border-color': themeColors.historyItemBorderColor,
        '--spinbutton-text-color': themeColors.spinbuttonTextColor,
        '--caret-down-hover-color': themeColors.caretDownHoverColor,
        '--spinbutton-input-background-color': themeColors.spinbuttonInputBackgroundColor,
        '--caret-down-color': themeColors.caretDownColor,
        '--close-button-hover-color': themeColors.closeButtonHoverColor,
        '--cross-button-background-color': themeColors.crossButtonBackgroundColor,
        '--cross-button-text-color': themeColors.crossButtonTextColor,
        '--cross-button-hover-color': themeColors.crossButtonHoverColor,
        '--checkbox-text-color': themeColors.checkboxTextColor,
        '--checkbox-border-color': themeColors.checkboxBorderColor,
        '--slider-text-color': themeColors.sliderTextColor,
        '--slider-active-section-color': themeColors.sliderActiveSectionColor,
        '--slider-inactive-section-color': themeColors.sliderInactiveSectionColor,
        '--spinbutton-arrow-background-color': themeColors.spinbuttonArrowBackgroundColor,
        '--dropdown-border-color': themeColors.dropdownBorderColor,
        '--stop-thinking-button-background-color': themeColors.stopThinkingButtonBackgroundColor,
        '--stop-thinking-button-text-color': themeColors.stopThinkingButtonTextColor,
        overflow: "hidden",
        height: "100%",
        maxHeight: "100vh"
    } as React.CSSProperties;

    return (
        <ThemeContext.Provider value={{ theme, themeColors, toggleTheme }}>
            <div style={layoutStyle}>
                {children}
            </div>
        </ThemeContext.Provider>
    );
};

export const useTheme = () => useContext(ThemeContext);