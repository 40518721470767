export const enum Approaches {
    ReadRetrieveRead = "rrr",
    ReadNative = "read",
}

export type ChatRequestOverrides = {
    semanticRanker?: boolean;
    filterCategory?: string;
    top?: number;
    temperature?: number;
    promptTemplate?: string;
    suggestFollowupQuestions?: boolean;
    gptVersion?: string;
    nativeSearch?: boolean;
    llmModelName?: string;
    agentType?:string;
    profile?:string;
    tools?: Array<Tools>;
    showPreviewFeatures?: boolean;
    use_files?: boolean;
};

export type ChatResponse = {
    answer: string;
    thoughts: string | null;
    data_points: SupportingContentData[];
    error?: string;
    error_code?: string;
    responseID?: string;
    feedbackType?: string;
    comment?: string;
    sharePrompt?: string;
    feedbackSubmissionTime?: string;
    conversationID?: string;
    issue?: string;
    settingProfile?: string;
    setting?: string;
    query?: string;
    overrides?: ChatRequestOverrides;
    userName?: string;
    appVersion: string;
    upload_session_id?: string;
};

export type ChatTurn = {
    user: string;
    bot?: string;
};

export type ChatRequest = {
    history: ChatTurn[];
    approach: Approaches;
    overrides?: ChatRequestOverrides;
    version: string;
    conversation_id: string;
    files?: any[]
    uploadSessionId?: string;
};

export type SupportingContentData = {
    title: string,
    content: string,
    source: string
}

export type AzureADTokenUserClaims = {
    typ: string,
    val: string
}

export type AzureADToken = {
    user_id: string,
    access_token?: string,
    id_token?:string,
    expires_on: string,
    provider_name: string,
    user_claims?: AzureADTokenUserClaims[]
}

export interface Profile {
    temperature: number,
    llmModelName: string,
    gptVersion: string,
    version: string,
    approach?: Approaches,
    nativeSearch : boolean,
    useSemanticRanker: boolean,
    agentType?: string,
    tools?: Array<Tools>,
    cardIdentifier: string,
}

export interface Tools {
    name: string,
    filters?: Array<string>,
}

export interface Cards {
    title: string,
    description: string,
    backDescription: string
}

export type FeedbackDataType = {
    feedbackType: string;
    comment: string;
    sharePrompt: string;
    feedbackSubmissionTime: string;
    responseID: string;
    issue: string;
};

export type Conversation = {
    title: string;
    messages: [user: string, response: ChatResponse][];
    conversationID: string;
    uploadedFiles?: string[];
    uploadSessionId?: string;
};