import { useRef, useState, useEffect } from "react";
import { Checkbox, Panel, DefaultButton, TextField, SpinButton, Toggle, Slider, Position } from "@fluentui/react";
import { Dropdown, DropdownMenuItemType, IDropdownOption, IDropdownStyles } from '@fluentui/react/lib/Dropdown';
import { ChatBubblesQuestionFilled } from "@fluentui/react-icons";
import { useLocation } from 'react-router-dom';

import { useTheme } from '../../state/themecontext';
import styles from "./Chat.module.css";

import { Answer, AnswerError, AnswerLoading } from "../../components/Answer";
import { Greeting } from "../../components/Answer/Greeting";
import { QuestionInput } from "../../components/QuestionInput";
import { UserChatMessage } from "../../components/UserChatMessage";
import { AnalysisPanel, AnalysisPanelTabs } from "../../components/AnalysisPanel";
import { SettingsButton } from "../../components/SettingsButton";
import { TwoButtonDialog, TwoButtonDialogHandle, ErrorDialog, ErrorDialogHandle } from "../../components/Dialogs";
import { isWebLink } from "../../utils/citations";
import ChatHistorySidebar from '../../components/ChatHistorySidebar/ChatHistorySidebar';
import { ShowSidebarButton } from "../../components/ShowSidebarButton";
import { UserGuideButton } from "../../components/UserGuideButton";
import { ReleaseNotesButton } from "../../components/ReleaseNotesButton";
import { FileUploadButton, FileUploadComponent } from "../../components/FileUpload";
import { AdminButton } from "../../components/AdminButton/AdminButton";
import { landingcard } from "../../config/descriptions";

import { chatApi, Approaches, ChatResponse, ChatRequest, ChatTurn, getCitationFilePath, sendFeedback, getCurrentVersion, disableAPI } from "../../api";
import { msalInstance } from "../../api/auth";
import { Tools, ChatRequestOverrides, FeedbackDataType, Conversation } from "../../api/models";
import { v4 as uuidv4 } from 'uuid';

import { DefaultNumberOfDocumentsToSearch, DefaultTemperature, DefaultLLMModelName, DefaultAgentType, DefaultAPIVersion, DefaultApproach, DefaultGPTVersion } from "../../config/apiconfig";
import { GreetingText, LLMModelNames, Profiles, AgentTypeList, DefaultProfile, Max_Interactions, ShowPreviewFeatures, ReleaseNotesUrl } from "../../config/frontendconfig";
import { ICheckboxStyles } from '@fluentui/react/lib/Checkbox';
import { ISliderStyles } from '@fluentui/react/lib/Slider';
import { IToggleStyles } from '@fluentui/react/lib/Toggle';
import { ISpinButtonStyles } from '@fluentui/react/lib/SpinButton';
import { ITextFieldStyles } from '@fluentui/react/lib/TextField';
import { IPanelStyles } from "@fluentui/react/lib/Panel";

const LLMModelNameOptions: IDropdownOption[] = [];
// First sort Company names of LLMs in Ascending Order
LLMModelNames.sort((a, b) => {
    const first = a.company.toLocaleUpperCase();
    const second = b.company.toLocaleUpperCase();
    if (first < second) {
        return -1;
    } else if (first > second) {
        return 1;
    } else {
        return 0;
    }
});
//Now sort model names in ascending order under company names
LLMModelNames.forEach((row, i) => {
    const models = row.models;
    models.sort((a, b) => {
        const first = a.model.toLocaleUpperCase();
        const second = b.model.toLocaleUpperCase();
        if (first < second) {
            return -1;
        } else if (first > second) {
            return 1;
        } else {
            return 0;
        }
    })
    models.forEach((modelrow, i) => {
        // Only add enabled models for now. Even though we could add it to the drop down
        // as disabled, at present we don't want to present it as an option
        if (modelrow.enabled === true) {
            // Add company name as Header to drop down for grouping models by company
            if (LLMModelNameOptions.findIndex((value, i) => value.key === row.company) === -1) {
                LLMModelNameOptions.push({ key: row.company, text: row.company, itemType: DropdownMenuItemType.Header });
            }
            LLMModelNameOptions.push({ key: modelrow.model, text: modelrow.model });
        }
    })
});
const AgentTypeOptions: IDropdownOption[] = [];
AgentTypeList.forEach((row, i) => {
    AgentTypeOptions.push({ key: row.key, text: row.description })
});

const getGptModelKey = (version: string): number => {
    switch (version) {
        case "3.5":
            return 1;
        case "4turbo":
            return 2;
        case "4":
            return 3;
        case "4omni":
            return 4;
        default:
            return 2;
    }
}

const ConversationCacheSuffix: string = "allConversations"

const Chat = () => {
    const location = useLocation();
    const [showPreviewFeatures, setShowPreviewFeatures] = useState<boolean>(ShowPreviewFeatures === "true");
    const selectedProfile = location.state?.cardIdentifier as string | undefined || DefaultProfile;
    // get the element in object Profiles whose name corresponds to the value of selectedProfile

    const profileConfig = Profiles[selectedProfile];
    const [isConfigPanelOpen, setIsConfigPanelOpen] = useState(false);
    const [isFileUploadPanelOpen, setIsFileUploadPanelOpen] = useState(false);
    const [promptTemplate, setPromptTemplate] = useState<string>("");
    const [retrieveCount, setRetrieveCount] = useState<number>(Number(localStorage.getItem('numberOfDocumentsToSearch')) || DefaultNumberOfDocumentsToSearch);
    const [useSemanticRanker, setUseSemanticRanker] = useState<boolean>(profileConfig?.useSemanticRanker || true);
    const [filterCategory, setFilterCategory] = useState<string>("");
    //A profile combines API version and approach.
    //Set profile here based on selection from landing page
    // Get value from React state
    // const selectedProfile = useSelector((state: RootState) => state.cardIdentifier.value)
    // Dispatch actions to update the store
    // store.dispatch({ type: "INCREMENT" });
    // store.dispatch({ type: "INCREMENT" });
    // store.dispatch({ type: "DECREMENT" });
    const [profile, setProfile] = useState<string>(selectedProfile);
    const [gptVersion, setGptVersion] = useState<string>(profileConfig?.gptVersion || DefaultGPTVersion);
    const [nativeSearch, setNativeSearch] = useState<boolean>(profileConfig?.nativeSearch || true);
    const [advancedSettings, setAdvancedSettings] = useState<boolean>(false);
    const [initialGreeting, setInitialGreeting] = useState<string>("Hello! " + GreetingText);
    const [userName, setUserName] = useState<string>("");
    const [temperature, setTemperature] = useState<number>(profileConfig?.temperature || DefaultTemperature);
    const [llmModelName, setLlmModelName] = useState<string>(profileConfig?.llmModelName || DefaultLLMModelName);
    const [agentType, setAgentType] = useState<string>(profileConfig?.agentType || DefaultAgentType);
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [gptModelKey, setGptModelKey] = useState<number>(getGptModelKey(gptVersion));
    const lastQuestionRef = useRef<string>("");
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
    const chatMessageStreamRef = useRef<HTMLDivElement>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [maxInteractionsReached, setMaxInteractionsReached] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();
    const [erroredConversation, setErroredConversation] = useState<number | null>(null);

    const [activeCitation, setActiveCitation] = useState<string>();
    const [activeAnalysisPanelTab, setActiveAnalysisPanelTab] = useState<AnalysisPanelTabs | undefined>(undefined);

    const [selectedAnswer, setSelectedAnswer] = useState<number>(0);
    const [answers, setAnswers] = useState<[user: string, response: ChatResponse][]>([]);
    const [allConversations, setAllConversations] = useState<Conversation[]>([]);
    const [activeConversationIndex, setActiveConversationIndex] = useState<number>(0);
    const [editedConversationIndex, setEditedConversationIndex] = useState<number | null>(null);
    const [editedTitle, setEditedTitle] = useState<string>("");
    const [originalTitle, setOriginalTitle] = useState<string>("");
    //hide history side panel when ppl or fdp profile is chosen until conversation history is stored
    // specific to profiles
    const [isSidebarVisible, setIsSidebarVisible] = useState(true);
    const confirmDialogRef = useRef<TwoButtonDialogHandle | null>(null);
    const errorDialogRef = useRef<ErrorDialogHandle | null>(null);
    const genericErrorDialogRef = useRef<ErrorDialogHandle | null>(null);
    const versionDialogRef = useRef<TwoButtonDialogHandle | null>(null);
    const timeoutDialogRef = useRef<TwoButtonDialogHandle | null>(null);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [conversationToDelete, setConversationToDelete] = useState<number | null>(null);
    //check if profileConfig.tools contains "websearch" and then activate or deactivate
    // const [useWebSearch, setUseWebSearch] = useState<boolean>((profileConfig && profileConfig.tools && profileConfig.tools.findIndex( row => row.name === "websearch") > -1) || false);
    //Web search is causing issues - timeouts, errors etc. Hence it is disabled by default and requires users to enable it.
    const [useWebSearch, setUseWebSearch] = useState<boolean>(false);
    const [inputText, setInputText] = useState('');
    const [hasAccess, setHasAccess] = useState<boolean>(false);

    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [uploadedFiles, setUploadedFiles] = useState<string[]>([]);
    const [isIndexing, setIsIndexing] = useState<boolean>(false);

    const [useUploadedFiles, setUseUploadedFiles] = useState<boolean>(false);
    const [reloadRequired, setReloadRequired] = useState<boolean>(false);
    const [isVisible, setIsVisible] = useState<boolean>(document.visibilityState === 'visible');
    const questionInputRef = useRef<{ focusTextField: () => void }>(null);
    const [conversationID, setConversationID] = useState<string>("");

    const [isInMultiDeleteMode, setIsInMultiDeleteMode] = useState<boolean>(false);

    const { themeColors } = useTheme();
    const dropdownStyles: Partial<IDropdownStyles> = {
        label: {
            color: themeColors.sliderTextColor,
        },
        dropdown: {
            selectors: {
                // Target the title when Dropdown is active
                ':focus .ms-Dropdown-title': {
                    color: themeColors.spinbuttonTextColor,
                },
                ':hover .ms-Dropdown-title': {
                    color: themeColors.spinbuttonTextColor,
                    borderColor: themeColors.dropdownBorderColor
                },
                ':active .ms-Dropdown-title': {
                    color: themeColors.spinbuttonTextColor,
                },
                ':focus .ms-Dropdown-caretDown': {
                    color: themeColors.caretDownHoverColor,
                },
                ':hover .ms-Dropdown-caretDown': {
                    color: themeColors.caretDownHoverColor,
                },
                ':active .ms-Dropdown-caretDown': {
                    color: themeColors.caretDownHoverColor,
                },
            },
            width: 300,
            marginBottom: '40px',
        },
        title: {
            color: themeColors.spinbuttonTextColor,
            backgroundColor: themeColors.spinbuttonInputBackgroundColor,
        },
        caretDown: {
            color: themeColors.caretDownColor
        },
        dropdownItem: {
            selectors: {
                ':hover': {
                    backgroundColor: themeColors.closeButtonHoverColor,
                    color: themeColors.spinbuttonTextColor,
                },
            },
            color: themeColors.spinbuttonTextColor
        },
        dropdownItemSelected: {
            selectors: {
                ':hover': {
                    backgroundColor: themeColors.closeButtonHoverColor,
                    color: themeColors.spinbuttonTextColor,
                },
            },
            backgroundColor: themeColors.closeButtonHoverColor,
            color: themeColors.spinbuttonTextColor
        },
        dropdownItems: {
            backgroundColor: themeColors.spinbuttonInputBackgroundColor
        }
    };
    const panelStyles: Partial<IPanelStyles> = {
        main: {
            backgroundColor: themeColors.fileUploadPanelBackgroundColor,
        },
        commands: {
            backgroundColor: themeColors.fileUploadPanelBackgroundColor,
            color: themeColors.fileUploadPanelTitleColor
        },
        footer: {
            backgroundColor: themeColors.fileUploadPanelBackgroundColor,
            border: 0
        },
        headerText: {
            color: themeColors.fileUploadPanelTitleColor,
            fontSize: 19
        },
        closeButton: {
            backgroundColor: themeColors.crossButtonBackgroundColor,
            color: themeColors.crossButtonTextColor,
            selectors: {
                ':hover': {
                    backgroundColor: themeColors.crossButtonHoverColor,
                    color: themeColors.crossButtonTextColor
                },
            },
        },
    };
    const checkboxStyles: ICheckboxStyles = {
        root: {
            selectors: {
                ':hover .ms-Checkbox-text': {
                    color: themeColors.checkboxTextColor,
                },
            },
        },
        checkbox: {
            borderColor: themeColors.checkboxBorderColor,
        },
        text: {
            color: themeColors.checkboxTextColor,
        },
    };
    const sliderStyles: Partial<ISliderStyles> = {
        titleLabel: {
            color: themeColors.sliderTextColor,
        },
        valueLabel: {
            color: themeColors.sliderTextColor,
        },
        activeSection: {
            backgroundColor: themeColors.sliderActiveSectionColor
        },
        inactiveSection: {
            backgroundColor: themeColors.sliderInactiveSectionColor,
        },
        slideBox: {
            selectors: {
                ':hover .ms-Slider-inactive': {
                    backgroundColor: themeColors.sliderInactiveSectionColor,
                },
            },
        },
    };
    const toggleStyles: Partial<IToggleStyles> = {
        text: {
            color: themeColors.sliderTextColor,
        },
    };
    const spinButtonStyles: Partial<ISpinButtonStyles> = {
        label: {
            color: themeColors.spinbuttonTextColor,
        },
        input: {
            backgroundColor: themeColors.spinbuttonInputBackgroundColor,
            color: themeColors.spinbuttonTextColor
        },
        arrowButtonsContainer: {
            selectors: {
                '.ms-UpButton:hover': {
                    backgroundColor: themeColors.spinbuttonArrowBackgroundColor,
                    color: "#000"
                },
                '.ms-DownButton:hover': {
                    backgroundColor: themeColors.spinbuttonArrowBackgroundColor,
                    color: "#000"
                }
            }
        },
    };
    const textFieldStyles: Partial<ITextFieldStyles> = {
        root: {
            color: themeColors.sliderTextColor,
            selectors: {
                '.ms-Label': {
                    color: themeColors.sliderTextColor,
                },
            },
        },
        field: {
            backgroundColor: themeColors.spinbuttonInputBackgroundColor,
            color: themeColors.spinbuttonTextColor,
        },
        description: {
            color: themeColors.crossButtonTextColor,
        },
    };
    const closeButtonStyle = {
        '--close-button-background-color': themeColors.closeButtonBackgroundColor,
        '--close-button-text-color': themeColors.closeButtonTextColor,
        '--close-button-hover-color': themeColors.closeButtonHoverColor
    } as React.CSSProperties;

    const pushDataLayerEvent = (componentName: string, componentType: string, interactionType: string, interactionValue: string, interactionUrl: string = 'n/a') => {
        // Determine the correct title
        const title = `Co-op GPT | ${profileConfig.cardIdentifier === "CoopGPTPPL" ? "PPL" : profileConfig.cardIdentifier}`;

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ component: null });
        window.dataLayer.push({
            event: 'component_interaction',
            component: {
                component_name: componentName,
                component_title: title,
                component_type: componentType,
                interaction_type: interactionType,
                interaction_value: interactionValue,
                interaction_url: interactionUrl
            }
        });
    };

    const [selectedToneOptionKey, setSelectedToneOptionKey] = useState('9');
    const [selectedDetailOptionKey, setSelectedDetailOptionKey] = useState('3');
    const [toneResponseString, setToneResponseString] = useState('');
    const toneDropdownOptions = [
        { key: '1', text: 'Casual' },
        { key: '2', text: 'Professional' },
        { key: '3', text: 'Conversational' },
        { key: '4', text: 'Humorous' },
        { key: '5', text: 'Simple' },
        { key: '6', text: 'Empathic' },
        { key: '7', text: 'Creative' },
        { key: '8', text: 'Academic' },
        { key: '9', text: 'None (Default)' }
    ];
    const detailDropdownOptions = [
        { key: '1', text: 'Long' },
        { key: '2', text: 'Short' },
        { key: '3', text: 'None (Default)' }
    ];
    const generateResponseString = () => {
        const toneOption = toneDropdownOptions.find(option => option.key === selectedToneOptionKey);
        const detailOption = detailDropdownOptions.find(option => option.key === selectedDetailOptionKey);
        const toneText = toneOption ? toneOption.text : '';
        const detailText = detailOption ? detailOption.text : '';
        const toneResponse = toneText !== 'None (Default)' ? toneText.toLowerCase() : '';
        const detailResponse = detailText !== 'None (Default)' ? detailText.toLowerCase() : '';

        if (toneResponse && detailResponse) {
            return `Please provide ${toneResponse} and ${detailResponse} response.`;
        }
        if (toneResponse) {
            return `Please provide ${toneResponse} response.`;
        }
        if (detailResponse) {
            return `Please provide ${detailResponse} response.`;
        }
        return '';
    };

    useEffect(() => {
        setToneResponseString(generateResponseString());
    }, [selectedToneOptionKey, selectedDetailOptionKey]);

    const onToneOptionChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        if (option) {
            setSelectedToneOptionKey(option.key.toString());
        }
    };

    const onDetailOptionChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        if (option) {
            setSelectedDetailOptionKey(option.key.toString());
        }
    };

    // On page load or refresh
    useEffect(() => {
        const cacheItem = profile + ConversationCacheSuffix;
        let storedConversations = localStorage.getItem(cacheItem);
        if (!storedConversations && profile === 'safegpt2') {
            storedConversations = localStorage.getItem('allConversations');
        }

        if (storedConversations) {
            let loadedConversations = JSON.parse(storedConversations);

            // Check if the conversations are in the old format (array of arrays)
            if (Array.isArray(loadedConversations) && loadedConversations.length > 0 && !loadedConversations[0].title) {
                // Transform the old format to the new format
                loadedConversations = loadedConversations.map((conversation) => ({
                    title: conversation.length > 0 ? conversation[0][0] : 'New Chat',
                    messages: conversation
                }));
            }

            if (Array.isArray(loadedConversations) && loadedConversations.length > 0) {
                loadedConversations = loadedConversations.map((conversation) => ({
                    ...conversation,
                    conversationID: conversation.conversationID || uuidv4(),
                    uploadedFiles: conversation.uploadedFiles || [], // Add this line
                    messages: conversation.messages.map(([message, response]: [string, ChatResponse]) => {
                        const { overrides, ...restResponse } = response;
                        return [
                            message,
                            {
                                ...restResponse,
                                ...overrides,
                                responseID: response.responseID || uuidv4(),
                                comment: response.comment || '',
                                sharePrompt: response.sharePrompt || '',
                                feedbackType: response.feedbackType || 'neutral',
                                feedbackSubmissionTime: response.feedbackSubmissionTime || '',
                                issue: response.issue || '',
                            }];
                    })
                }));
            }

            setAllConversations(loadedConversations);

            // Set the answers state to the last conversation
            if (loadedConversations.length > 0) {
                const lastConversation = loadedConversations[loadedConversations.length - 1];
                setAnswers(lastConversation.messages);
                setActiveConversationIndex(loadedConversations.length - 1);
                setUploadedFiles(lastConversation.uploadedFiles || []);

                // Update the lastQuestionRef to the last user's message, if it exists
                if (lastConversation.messages.length > 0) {
                    lastQuestionRef.current = lastConversation.messages[lastConversation.messages.length - 1][0];
                }
                setMaxInteractionsReached(lastConversation.messages.length >= Max_Interactions);
            }
        } else {
            // If there are no stored conversations, initialize with an empty one
            const defaultConversation = {
                title: 'New Chat',
                messages: [],
                conversationID: uuidv4(),
                uploadedFiles: [] // Add this line
            };
            setAllConversations([defaultConversation]);
            setActiveConversationIndex(0);
            setAnswers([]);
            setMaxInteractionsReached(false);
        }
    }, []);

    // When answers state changes
    useEffect(() => {
        if (answers.length === 0) return;

        const updatedConversations = [...allConversations];
        updatedConversations[activeConversationIndex].messages = answers;
        setAllConversations(updatedConversations);
        const cacheItem = profile + ConversationCacheSuffix;
        try {
            localStorage.setItem(cacheItem, JSON.stringify(updatedConversations));
            // If there are both allConversations and safegpt2allConversations, we can delete allConversations from localStorage
            if (localStorage.getItem('allConversations') && localStorage.getItem('safegpt2allConversations')) {
                localStorage.removeItem('allConversations');
            }
        } catch (error) {
            // local storage is full
            // if there is only one conversation in answers then this was a new chat, need to reset
            if (answers.length === 1) {
                lastQuestionRef.current = '';
                updatedConversations[activeConversationIndex].title = 'New Chat';
            }
            // remove the latest conversation and alert the user to delete a chat and clear up memory
            setAnswers(answers.slice(0, -1))
            updatedConversations[activeConversationIndex].messages = answers;
            setAllConversations(updatedConversations);
            if (errorDialogRef.current) {
                errorDialogRef.current.handleClickOpen();
            }
        }
    }, [answers, activeConversationIndex]);

    useEffect(() => {
        const updatePageInfo = () => {
            // Set the document title
            document.title = `Co-op GPT | ${profileConfig.cardIdentifier === "CoopGPTPPL" ? "PPL" : profileConfig.cardIdentifier}`;

            // Push page_init event to dataLayer
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'page_init',
                user: {
                    id: msalInstance.getActiveAccount()?.homeAccountId || '',
                    loginStatus: msalInstance.getActiveAccount() ? 'logged in' : 'logged out'
                },
                page: {
                    id: 'chat',
                    type: 'chat',
                    url: window.location.href,
                    title: document.title
                }
            });
        };

        updatePageInfo();
    }, [profileConfig.cardIdentifier]); // Re-run when chat type changes

    // Focus question text field when chat changes or upon new answer
    useEffect(() => {
        if (questionInputRef.current) {
            questionInputRef.current.focusTextField();
        }
    }, [answers, activeConversationIndex, allConversations]);

    // Toggle side bar on resize
    useEffect(() => {
        function handleResize() {
            if (window.innerWidth <= 1100) {
                setIsSidebarVisible(false);
            } else {
                setIsSidebarVisible(true);
            }
        }

        // Set the initial state
        handleResize();

        window.addEventListener("resize", handleResize);

        // Clean up
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        // scroll to bottom of conversation history
        if (chatMessageStreamRef.current) {
            const { scrollHeight, clientHeight } = chatMessageStreamRef.current;
            chatMessageStreamRef.current.scrollTop = scrollHeight - clientHeight;
        }
    }, [answers]);

    useEffect(() => {
        if (retrieveCount < 50) {
            setRetrieveCount(50);
        }
    }, []);

    // Function to update conversations, no need to update localStorage here as the useEffect will handle that.
    const updateConversations = () => {
        const updatedConversations = [...allConversations];
        updatedConversations[activeConversationIndex].messages = answers;
        setAllConversations(updatedConversations);
        const cacheItem = profile + ConversationCacheSuffix;
        try {
            localStorage.setItem(cacheItem, JSON.stringify(updatedConversations));
        } catch (error) {
            setAnswers(answers.slice(0, -1));
            updatedConversations[activeConversationIndex].messages = answers;
            setAllConversations(updatedConversations);
            localStorage.setItem(cacheItem, JSON.stringify(updatedConversations));
            if (errorDialogRef.current) {
                errorDialogRef.current.handleClickOpen();
            }
        }

    };

    useEffect(() => {
        if (reloadRequired) {
            if (versionDialogRef.current) {
                versionDialogRef.current.handleClickOpen();
            }
        }
    }, [reloadRequired])

    useEffect(() => {
        function handleVisibilityChange() {
            setIsVisible(document.visibilityState === 'visible');
        }
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => { document.removeEventListener('visibilitychange', handleVisibilityChange) };
    }, []);

    useEffect(() => {
        if (isVisible) {
            compareVersion();
        }
    }, [isVisible]);

    const handleNewChatRequired = (flag: boolean) => {
        if (flag) {
            clearChat();
        }
    }

    const compareVersion = async () => {
        if (document.visibilityState === 'visible') {
            // check the version
            try {
                const currentVersion: String = await getCurrentVersion();
                const localVersion: String = localStorage.getItem('appVersion') || "undefined";
                if (currentVersion !== localVersion) {
                    // alert the user
                    setReloadRequired(true);
                }
            } catch (error: any) {
                if (error.message == "Connection timeout") {
                    if (timeoutDialogRef.current) {
                        timeoutDialogRef.current.handleClickOpen();
                    }
                } else {
                    setErrorMessage("There was an error. Please refresh the page.");
                    if  (genericErrorDialogRef.current) {
                        genericErrorDialogRef.current.handleClickOpen();
                    }
                }
            }
        }
    }

    const onRenameConversation = (index: number) => {
        setEditedConversationIndex(index);
        setOriginalTitle(allConversations[index].title); // Store the original title
        setEditedTitle(''); // Set the edited title to an empty string
    };

    const handleTitleChange = (index: number, newTitle: string) => {
        setEditedTitle(newTitle);
    };

    const handleIndexDeletion = () => {
        // additional logic here if needed
    };

    const handleTitleSave = (index: number) => {
        if (editedTitle.trim() === '') {
            setEditedTitle(originalTitle); // Revert to original title if edited title is empty
        } else {
            setAllConversations(prevConversations => {
                const updatedConversations = [...prevConversations];
                updatedConversations[index].title = editedTitle;
                const cacheItem = profile + ConversationCacheSuffix;
                try {
                    localStorage.setItem(cacheItem, JSON.stringify(updatedConversations));
                } catch (error) {
                    updatedConversations[index].title = originalTitle;
                    localStorage.setItem(cacheItem, JSON.stringify(updatedConversations));
                    if (errorDialogRef.current) {
                        errorDialogRef.current.handleClickOpen();
                    }
                }

                return updatedConversations;
            });
        }
        setEditedConversationIndex(null); // Exit edit mode
    };

    // When switching to an old conversation
    const switchToConversation = (index: number) => {
        // Persist the current state of answers into allConversations
        updateConversations();

        const selectedConversation = allConversations[index];
        setAnswers(selectedConversation.messages);
        setActiveConversationIndex(index);

        // Update the lastQuestionRef based on the selected conversation
        if (selectedConversation.messages.length > 0 && selectedConversation.messages.length % 2 !== 0) {
            lastQuestionRef.current = selectedConversation.messages[selectedConversation.messages.length - 1][0];
        } else if (selectedConversation.messages.length > 1) {
            lastQuestionRef.current = selectedConversation.messages[selectedConversation.messages.length - 2][0];
        } else {
            lastQuestionRef.current = ""; // Reset it for a new conversation
        }

        // update the conversations
        updateConversations();

        // clear files when switching conversations and close panel
        setSelectedFiles([]);
        setUploadedFiles(selectedConversation.uploadedFiles || []);
        setIsFileUploadPanelOpen(false);
        setMaxInteractionsReached(selectedConversation.messages.length >= Max_Interactions);
    };

    const handlePromptSelection = (text: string) => {
        setInputText(text);
    };

    const handleEditedMessage = (index: number, newMessage: string, isEdited: boolean) => {
        setAnswers(prevAnswers => {
            // Remove all answers subsequent to edited question
            const updatedAnswers = prevAnswers.slice(0, index);
            // Now make the API request with the updated state/edited question
            makeApiRequest(newMessage, updatedAnswers, isEdited);
            return updatedAnswers;
        });
    };

    const abortControllerRef = useRef<AbortController | null>(null);

    const makeApiRequest = async (question: string, updatedAnswers: [user: string, response: ChatResponse][], isEdited: boolean = false) => {
        if (maxInteractionsReached) {
            window.alert("Maximum interactions reached, please start a new chat")
            return;
        }

        abortControllerRef.current = new AbortController();

        const modifiedQuestion = `${toneResponseString} ${question}`;
        lastQuestionRef.current = question;

        error && setError(undefined);
        setErroredConversation(null);
        setIsLoading(true);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);

        try {
            const history: ChatTurn[] = updatedAnswers.map(a => ({ user: a[0], bot: a[1].answer }));
            const profileConfig = Profiles[profile];
            let tools: Array<Tools> = [];
            //Profile config controls what tools are available.
            //If websearch is wanted, then let profile config control whether it is used or not
            //If websearch is not wanted then remove that as a tool.
            if (useWebSearch === false && profileConfig && profileConfig.tools) {
                //append rows of profileConfig.tools where name != websearch to tools
                tools = profileConfig.tools.filter(row => row.name != 'websearch');
            } else if (useWebSearch === true && profileConfig && profileConfig.tools) {
                tools = profileConfig.tools;
            }

            const currentConversation = allConversations[activeConversationIndex];
            const hasUploadedFiles = currentConversation.uploadedFiles && currentConversation.uploadedFiles.length > 0;

            const overrides: ChatRequestOverrides = {
                promptTemplate: promptTemplate.length === 0 ? undefined : promptTemplate,
                filterCategory: filterCategory.length === 0 ? undefined : filterCategory,
                top: retrieveCount,
                semanticRanker: useSemanticRanker,
                gptVersion: gptVersion,
                nativeSearch: nativeSearch,
                temperature: temperature,
                llmModelName: llmModelName,
                agentType: agentType,
                profile: profile,
                tools: tools,
                showPreviewFeatures: showPreviewFeatures,
                use_files: hasUploadedFiles,
            };

            const request: ChatRequest = {
                history: [...history, { user: modifiedQuestion, bot: undefined }],
                approach: profileConfig?.approach || DefaultApproach,
                version: profileConfig?.version || DefaultAPIVersion,
                overrides: overrides,
                files: selectedFiles ? selectedFiles : undefined,
                conversation_id: currentConversation.conversationID,
                uploadSessionId: currentConversation.uploadSessionId || '',
            };

            try {
                const result = await chatApi(request, abortControllerRef.current.signal);
                result.responseID = uuidv4();
                result.comment = result.comment || '';
                result.feedbackType = result.feedbackType || '';
                result.sharePrompt = result.sharePrompt || '';
                result.feedbackSubmissionTime = result.feedbackSubmissionTime || '';
                result.issue = result.issue || '';
                Object.assign(result, overrides);
                try {
                    setAnswers([...updatedAnswers, [question, result]]);
                } catch (error) {
                    if (versionDialogRef.current) {
                        versionDialogRef.current.handleClickOpen();
                    }
                }
            } catch (error: any) {
                if (error.message === "Request was cancelled") {
                    return;
                }
                
                if (error.message === "Error Response from API: Connection timeout") {
                    if (timeoutDialogRef.current) {
                        timeoutDialogRef.current.handleClickOpen();
                    }
                } else if (error.message) {
                    setErrorMessage(error.message);
                    if (genericErrorDialogRef.current) {
                        genericErrorDialogRef.current.handleClickOpen();
                    }
                } else {
                    setErrorMessage("There was an error. Please refresh the page.");
                    if (genericErrorDialogRef.current) {
                        genericErrorDialogRef.current.handleClickOpen();
                    }
                }
            }
            // Set the title to the first user message if no explicit title is set
            setAllConversations(prevConversations => {
                const updatedConversations = [...prevConversations];
                const conversation = updatedConversations[activeConversationIndex];
                if (!conversation.title || conversation.title === 'New Chat') {
                    conversation.title = question;
                }
                const cacheItem = profile + ConversationCacheSuffix;
                try {
                    localStorage.setItem(cacheItem, JSON.stringify(updatedConversations));
                } catch (error) {
                    // triggered when the first message of the chat becomes the title of the chat, and that overflows the local storage
                    conversation.title = 'New Chat';
                    // we know there is less memory than the length of the question, so trying to save the question and answer will cause an overflow later
                    // so we continue for now and catch the error later so we only alert the user once
                }

                return updatedConversations;
            });

            updateConversations();

            if (!isEdited) {
                // Push data layer event for new prompt submit
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({ component: null });
                window.dataLayer.push({
                    event: 'prompt_submit',
                    component: {
                        component_name: 'new prompt actions',
                        component_title: document.title,
                        component_type: 'card',
                        interaction_type: 'submit',
                        interaction_value: 'submit',
                        interaction_url: 'n/a'
                    }
                });
            }
        } catch (e: any) {
            if (e.message === "Request was cancelled") {
                return;
            }
            
            if (e.message === "Index not found. Please reupload files.") {
                setError("The uploaded files are no longer available. Please reupload the files.");
                // Remove uploadedFiles from the current conversation in localStorage
                const updatedConversations = [...allConversations];
                updatedConversations[activeConversationIndex].uploadedFiles = [];
                setAllConversations(updatedConversations);
                const cacheItem = profile + ConversationCacheSuffix;
                localStorage.setItem(cacheItem, JSON.stringify(updatedConversations));
                setUploadedFiles([]);
            } else {
                setError(e);
            }
            setErroredConversation(activeConversationIndex);
        } finally {
            setIsLoading(false);
            setMaxInteractionsReached(updatedAnswers.length >= Max_Interactions);
            abortControllerRef.current = null;
        }
    };

    const clearChat = () => {
        setAnswers([]);
        setUploadedFiles([]);
        lastQuestionRef.current = "";

        // First ensure that a new chat does not overfill the local storage
        const updatedConversation = [...allConversations, { title: 'New Chat', messages: [], conversationID: uuidv4(), uploadedFiles: [] }];
        const cacheItem = profile + ConversationCacheSuffix;
        try {
            localStorage.setItem(cacheItem, JSON.stringify(updatedConversation));
            // Update allConversations first
            setAllConversations(updatedConversation);

            // Update the index after updating allConversations
            setActiveConversationIndex(allConversations.length);

            // Clear other states
            error && setError(undefined);
            setErroredConversation(null);
            setActiveCitation(undefined);
            setActiveAnalysisPanelTab(undefined);
            // clear files when starting new conversation and close panel
            setSelectedFiles([]);
            setIsFileUploadPanelOpen(false);
            setMaxInteractionsReached(false);
        } catch (error) {
            // New chat overfills the local storage, switch to existing chat in the meantime and alert the user to delete a chat
            setActiveConversationIndex(0);
            setAnswers(allConversations[0].messages);
            lastQuestionRef.current = allConversations[0].messages[allConversations[0].messages.length - 1][0];
            updateConversations();
            if (errorDialogRef.current) {
                errorDialogRef.current.handleClickOpen();
            }
        }


    };

    const handleClearFiles = () => {
        setSelectedFiles([]);
        setUploadedFiles([]);
        setUseUploadedFiles(false);

        // Update local storage
        const updatedConversations = [...allConversations];
        updatedConversations[activeConversationIndex].uploadedFiles = [];
        setAllConversations(updatedConversations);
        const cacheItem = profile + ConversationCacheSuffix;
        localStorage.setItem(cacheItem, JSON.stringify(updatedConversations));
    };

    const [conversationsToDelete, setConversationsToDelete] = useState<number[]>([]);

    const handleDeleteMultipleConversations = (indices: number[]) => {
        setConversationsToDelete(indices);
        setIsInMultiDeleteMode(true);
        if (confirmDialogRef.current) {
            confirmDialogRef.current.handleClickOpen();
        }
    };

    const handleDeleteConversation = (flag: boolean) => {
        if (flag) {
            let indicesToDelete: number[];
            if (conversationsToDelete.length > 0) {
                indicesToDelete = conversationsToDelete;
            } else if (conversationToDelete !== null) {
                indicesToDelete = [conversationToDelete];
            } else {
                return;
            }

            // Sort indices in descending order to avoid index shifting issues when deleting
            indicesToDelete.sort((a, b) => b - a);

            // Create a shallow copy of allConversations
            const updatedConversations = [...allConversations];

            // Remove the conversations
            for (const index of indicesToDelete) {
                updatedConversations.splice(index, 1);
            }

            // If we deleted all conversations, create a new empty one
            if (updatedConversations.length === 0) {
                updatedConversations.push({ title: 'New Chat', messages: [], conversationID: uuidv4() });
            }

            // Adjust the activeConversationIndex if needed
            const newIndex = Math.min(activeConversationIndex, updatedConversations.length - 1);

            // Update state
            setAllConversations(updatedConversations);
            setActiveConversationIndex(newIndex);
            setAnswers(updatedConversations[newIndex].messages || []);
            setUploadedFiles(updatedConversations[newIndex].uploadedFiles || []);

            // Update lastQuestionRef
            const newActiveConversation = updatedConversations[newIndex].messages;
            if (newActiveConversation.length > 0 && newActiveConversation.length % 2 !== 0) {
                lastQuestionRef.current = newActiveConversation[newActiveConversation.length - 1][0];
            } else if (newActiveConversation.length > 1) {
                lastQuestionRef.current = newActiveConversation[newActiveConversation.length - 2][0];
            } else {
                lastQuestionRef.current = "";
            }

            // Update local storage
            const cacheItem = profile + ConversationCacheSuffix;
            try {
                localStorage.setItem(cacheItem, JSON.stringify(updatedConversations));
            } catch (error) {
                if (errorDialogRef.current) {
                    errorDialogRef.current.handleClickOpen();
                }
            }

            // Reset states
            setConversationToDelete(null);
            setConversationsToDelete([]);
            setSelectedFiles([]);
            setIsFileUploadPanelOpen(false);
            setMaxInteractionsReached(updatedConversations[newIndex].messages.length >= Max_Interactions);
            // Reset multi-delete mode only after successful deletion
            setIsInMultiDeleteMode(false);
        } else {
            // If user cancels, just clear the conversations to delete
            setConversationToDelete(null);
            setConversationsToDelete([]);
        }
    };

    const handleVersionUpdateRequired = (flag: boolean) => {
        if (flag) {
            window.location.reload();
        } else {
            disableAPI();
        }
    }

    // update the local version to current App Version
    const updateCurrentVersion = async () => {
        try {
            const currentVersion: string = await getCurrentVersion();
            localStorage.setItem('appVersion', currentVersion);
        } catch (error) {
            // catch local storage overflow error
            if (errorDialogRef.current) {
                errorDialogRef.current.handleClickOpen();
            }
        }
    };
    // This will trigger upon reload and re-render! We have to be careful for the case when users navigate away and back again
    // Therefore, we do not enable the API here, but instead update the version and set reload required to false
    // so that if this was a reload the error dialog is disabled and local version updated.
    // If a re-render triggers this, the API will still be disabled until the user reloads, so it is ok to update the version preemptively.
    useEffect(() => {
        setReloadRequired(false);
        updateCurrentVersion();
    }, []);

    useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" }), [isLoading]);
    useEffect(() => {
        if (answers.length > 0 || userName !== "") return;
        (async () => {
            const name: string = msalInstance.getActiveAccount()?.name || "";
            setUserName(name);
        })();
    });

    useEffect(() => {
        if (allConversations[activeConversationIndex]) {
            const conversationID = allConversations[activeConversationIndex].conversationID;
            setConversationID(conversationID);
        } else {
            console.warn('Active conversation is undefined');
        }
    }, [allConversations, activeConversationIndex]);

    useEffect(() => {
        const checkRoles = async () => {
            const account = msalInstance.getActiveAccount();
            if (account) {
                const roles = account.idTokenClaims?.roles || [];
                if (roles.includes('Manage.All')) {
                    setHasAccess(true);
                } else {
                    setHasAccess(false);
                }
            }
        };
        checkRoles();
    }, [])

    useEffect(() => {
        if ((userName != null) && (userName != ""))
            setInitialGreeting("Hello " + userName.split(' ', 1) + GreetingText);
    }, [userName]);

    const onPromptTemplateChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setPromptTemplate(newValue || "");
    };

    const onRetrieveCountChange = (_ev?: React.SyntheticEvent<HTMLElement, Event>, newValue?: string) => {
        setRetrieveCount(parseInt(newValue || DefaultNumberOfDocumentsToSearch.toString()));
        try {
            localStorage.setItem('numberOfDocumentsToSearch', newValue || DefaultNumberOfDocumentsToSearch.toString())
        } catch (error) {
            if (errorDialogRef.current) {
                errorDialogRef.current.handleClickOpen();
            }
        }
    };

    const onUseSemanticRankerChange = (_ev?: React.MouseEvent<HTMLElement>, checked?: boolean) => {
        setUseSemanticRanker(!!checked);
    };

    const onUseWebSearchChange = (_ev?: React.MouseEvent<HTMLElement>, checked?: boolean) => {
        setUseWebSearch(!!checked);

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ component: null });
        window.dataLayer.push({
            event: 'component_interaction',
            component: {
                component_name: 'ai settings',
                component_title: 'web search toggle',
                component_type: 'button',
                interaction_type: 'click',
                interaction_value: `toggle:${!!checked ? 'on' : 'off'}`,
                interaction_url: 'n/a'
            }
        });
    };

    // const onFilterCategoryChanged = (_ev?: React.FormEvent, newValue?: string) => {
    //     setFilterCategory(newValue || "");
    // };

    // const onGptVersionChange = (_ev?: React.MouseEvent<HTMLElement>, checked?: boolean) => {
    //     setGptVersion(!!checked);
    // };

    const onNativeSearchChange = (_ev?: React.MouseEvent<HTMLElement>, checked?: boolean) => {
        setNativeSearch(!!checked);
    };

    const onAdvancedSettingsChange = (_ev?: React.MouseEvent<HTMLElement>, checked?: boolean) => {
        setAdvancedSettings(!!checked);
    };

    const onTemperatureChange = (value: number, range?: [number, number] | undefined, event?: MouseEvent | React.MouseEvent<Element, MouseEvent> | React.TouchEvent<Element> | TouchEvent | React.KeyboardEvent<Element> | undefined) => {
        setTemperature(value);
    };

    const onGptModelKeyChange = (value: number, _ev?: React.FormEvent<HTMLDivElement>) => {
        setGptModelKey(value);
        setGptVersion(getGPTModelName(value));
    };

    const onProfileChange = (_ev?: React.MouseEvent<HTMLElement>, checked?: boolean) => {
        const profile = checked ? "coopgptppl2" : DefaultProfile;
        setProfile(profile);
    }

    const onShowCitation = (citation: string, index: number) => {
        const dataPoints = answers[index][1].data_points;

        const normalize = (str: string) => str.replace(/^\d+\.\s*/, '').toLowerCase();
        const normalizedCitation = normalize(citation);

        const citationLink = dataPoints?.find(d => {
            const normalizedTitle = normalize(d.title);
            if (normalizedTitle === normalizedCitation) {
                return true;
            } else if (normalizedCitation.includes("::")) {
                const citationParts = normalizedCitation.split("::");
                return citationParts[0] === normalizedTitle;
            } else if (normalizedCitation.includes(":")) {
                const citationParts = normalizedCitation.split(":");
                return citationParts[0] === normalizedTitle;
            } else if (normalizedCitation.includes(",")) {
                const citationParts = normalizedCitation.split(",");
                return citationParts[0] === normalizedTitle;
            } else {
                return normalizedCitation.includes(normalizedTitle) || normalizedTitle.includes(normalizedCitation);
            }
        })?.source;

        if (citationLink && isWebLink(citationLink ? citationLink : "")) {
            pushDataLayerEvent('response actions', 'link', 'click', citation, citationLink);
            window.open(citationLink, "_blank");
        }
        // Check if citation is a direct URL or starts with www.
        if (/^https?:\/\//.test(citation) || /^www\./.test(citation)) {
            // Ensure the citation has the correct protocol if it starts with www.
            const completeCitation = citation.startsWith('www.') ? `https://${citation}` : citation;
            pushDataLayerEvent('response actions', 'link', 'click', citation, completeCitation);
            window.open(completeCitation, "_blank");
        }
        // Check for formatted citation string
        else if (citation.includes("::")) {
            const parts = citation.split("::");
            // Check if the part after :: is a direct URL or starts with www.
            if (parts.length > 1 && (/^https?:\/\//.test(parts[1]) || /^www\./.test(parts[1]))) {
                // Ensure the citation has the correct protocol if it starts with www.
                const completeCitation = parts[1].startsWith('www.') ? `https://${parts[1]}` : parts[1];
                pushDataLayerEvent('response actions', 'link', 'click', citation, completeCitation);
                window.open(completeCitation, "_blank");
            }
        }

        setSelectedAnswer(index);
    };

    const onToggleTab = (tab: AnalysisPanelTabs, index: number) => {
        if (activeAnalysisPanelTab === tab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveAnalysisPanelTab(tab);
            if (tab === AnalysisPanelTabs.ThoughtProcessTab) {
                pushDataLayerEvent('response actions', 'icon', 'click', 'show thought process');
            } else if (tab === AnalysisPanelTabs.SupportingContentTab) {
                pushDataLayerEvent('response actions', 'icon', 'click', 'show supporting content');
            }
        }
        setSelectedAnswer(index);
    };

    const getTemperatureDescription = (value: number) => {
        if (value < 0.3) {
            return 'Creativity of AI response - Precise';
        } else if (value >= 0.3 && value <= 0.6) {
            return 'Creativity of AI response - Balanced';
        } else if (value >= 0.7 && value < 1.0) {
            return 'Creativity of AI response - Creative';
        } else {
            return 'Creativity of AI response - Artistic';
        }
    };
    const getGPTModelName = (value: number) => {
        switch (value) {
            case 1:
                return '3.5';
            case 2:
                return '4turbo';
            case 3:
                return '4';
            case 4:
                return '4omni';
            default:
                return '4turbo';
        }
    };
    const getGPTLabel = (value: number) => {
        switch (value) {
            case 1:
                return 'GPT 3.5';
            case 2:
                return 'GPT 4 Turbo';
            case 3:
                return 'GPT 4 32k';
            case 4:
                return 'GPT 4 Omni';
            default:
                return 'GPT 4 Turbo';
        }
    };

    const getFileContent = (selectedFiles: any[]): Promise<Array<{ name: string, type: string, size: number, content: string }>> => {
        return Promise.all(Array.from(selectedFiles).map(async file => {
            // Reading file text works well for text files but not for
            // binary files like PDFs
            const content = await file.text();
            return { name: file.name, type: file.type, size: file.size, content };
        }));
    }

    const clearUploadSessionId = () => {
        const updatedConversations = [...allConversations];
        updatedConversations[activeConversationIndex].uploadSessionId = '';
        setAllConversations(updatedConversations);
        const cacheItem = profile + ConversationCacheSuffix;
        localStorage.setItem(cacheItem, JSON.stringify(updatedConversations));
    };

    const onFileUpload = (files: File[], isFileUploadPanelOpen: boolean, fileNames: string[], deletedFiles: string[], uploadSessionId: string) => {
        setSelectedFiles(prevFiles => Array.from(new Set([...prevFiles, ...files])));
        setUploadedFiles(prevFiles => Array.from(new Set([...prevFiles, ...fileNames])));
        setIsFileUploadPanelOpen(isFileUploadPanelOpen);

        // Create a copy of all conversations
        const updatedConversations = [...allConversations];
        const currentUploadedFiles = updatedConversations[activeConversationIndex].uploadedFiles || [];

        // Update uploaded files for the active conversation
        updatedConversations[activeConversationIndex].uploadedFiles = Array.from(new Set([
            ...currentUploadedFiles.filter(file => !deletedFiles.includes(file)),
            ...fileNames
        ]));

        // Set the uploadSessionId
        updatedConversations[activeConversationIndex].uploadSessionId = uploadSessionId || '';

        // Update the state with the modified conversations
        setAllConversations(updatedConversations);

        // Save to local storage
        const cacheItem = profile + ConversationCacheSuffix;
        localStorage.setItem(cacheItem, JSON.stringify(updatedConversations));

        // Set indexing state to true when upload starts
        setIsIndexing(true);
        setUseUploadedFiles(true);
    }

    const handleIndexingComplete = () => {
        setIsIndexing(false);
    }

    const onFileUploadLinkClick = (isFileUploadPanelOpen: boolean) => {
        setIsFileUploadPanelOpen(isFileUploadPanelOpen);
    }

    const handleFeedbackSubmit = async (feedbackData: FeedbackDataType) => {
        try {
            // Update the local cache with feedback
            const updatedConversations = [...allConversations];
            const conversationID = allConversations[activeConversationIndex].conversationID;
            const conversationIndex = updatedConversations.findIndex(convo => convo.conversationID === conversationID);
            if (conversationIndex !== -1) {
                const responseIndex = updatedConversations[conversationIndex].messages.findIndex(([_, response]) => response.responseID === feedbackData.responseID);
                if (responseIndex !== -1) {
                    const response = updatedConversations[conversationIndex].messages[responseIndex][1];

                    // Map feedbackData (user submitted) to response (local cache) object. Basically update local cache with user feedback.
                    response.comment = feedbackData.comment;
                    response.feedbackSubmissionTime = feedbackData.feedbackSubmissionTime;
                    response.feedbackType = feedbackData.feedbackType;
                    response.issue = feedbackData.issue;
                    response.sharePrompt = feedbackData.sharePrompt;

                    const cacheItem = profile + ConversationCacheSuffix;
                    localStorage.setItem(cacheItem, JSON.stringify(updatedConversations));
                    setAllConversations(updatedConversations);

                    const shouldShare = response.sharePrompt === 'yes';
                    const appVersion = import.meta.env.VITE_APP_VERSION || "Preview 0.5";
                    const sendFeedbackData: ChatResponse = {
                        ...response,
                        userName: userName,
                        appVersion: appVersion,
                        query: shouldShare ? updatedConversations[conversationIndex].messages[responseIndex][0] : '',
                        answer: shouldShare ? response.answer : '',
                        thoughts: shouldShare ? response.thoughts : '',
                        data_points: shouldShare ? response.data_points : [],
                        error: shouldShare ? response.error : '',
                        error_code: shouldShare ? response.error_code : '',
                        conversationID: shouldShare ? conversationID : '',
                        responseID: shouldShare ? response.responseID : '',
                    };
                    try {
                        await sendFeedback(sendFeedbackData);
                        pushDataLayerEvent('response actions', 'icon', 'click', feedbackData.feedbackType === 'positive' ? 'thumbs up' : 'thumbs down');
                    } catch (error) {
                        if (versionDialogRef.current) {
                            versionDialogRef.current.handleClickOpen();
                        }
                    }
                }
            }
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    };

    const handleStopThinking = () => {
        if (abortControllerRef.current) {
            // Cancel the API request
            abortControllerRef.current.abort();
            abortControllerRef.current = null;
            
            // Reset loading state
            setIsLoading(false);
            
            // Check if this was the first message in a new chat
            const isNewChat = answers.length === 0;
            
            // Store the question text before clearing lastQuestionRef
            const questionText = lastQuestionRef.current;
            
            if (isNewChat) {
                // For new chat, clear lastQuestionRef to show greeting
                lastQuestionRef.current = "";
                // Use setTimeout to ensure the input text is set after the component re-renders
                setTimeout(() => setInputText(questionText), 0);
            } else {
                // For existing chats, restore their question to the input box
                setInputText(questionText);
            }
        }
    };

    return (
        <div className={styles.container}>
            {/* Conditionally render ChatHistorySidebar based on the activeAnalysisPanelTab */}
            {isSidebarVisible && !activeAnalysisPanelTab && (
                <ChatHistorySidebar
                    chatHistory={allConversations}
                    clearChat={clearChat}
                    onConversationSelected={switchToConversation}
                    activeConversationIndex={activeConversationIndex}
                    isLoading={isLoading}
                    toggleSidebarVisibility={() => setIsSidebarVisible(prevState => !prevState)}
                    isNewChatEmpty={allConversations.length > 0 && allConversations[allConversations.length - 1].messages.length === 0}
                    onDeleteConversation={(index) => {
                        setConversationToDelete(index);
                        if (confirmDialogRef.current) {
                            confirmDialogRef.current.handleClickOpen();
                        }
                    }}
                    handlePromptSelection={handlePromptSelection}
                    onRenameConversation={onRenameConversation}
                    handleTitleChange={handleTitleChange}
                    handleTitleSave={handleTitleSave}
                    editedConversationIndex={editedConversationIndex}
                    editedTitle={editedTitle}
                    originalTitle={originalTitle}
                    profile={profile}
                    onDeleteMultipleConversations={handleDeleteMultipleConversations}
                    isInMultiDeleteMode={isInMultiDeleteMode}
                    setIsInMultiDeleteMode={setIsInMultiDeleteMode}
                />
            )}
            <div className={`${isSidebarVisible && !activeAnalysisPanelTab ? styles.chatAreaSideBarVisible : styles.chatArea}`}>
                <div className={styles.commandsContainer}>
                    {!isSidebarVisible && (
                        <ShowSidebarButton className={`${styles.commandButton} ${styles.leftAlignedButtons}`} onClick={() => setIsSidebarVisible(true)} />
                    )}
                    {(profile === "coopgptppl" || profile === "coopgptppl2") && (
                        <div className={styles.newChatMessage}>
                            To query a new topic, please start a new chat.
                        </div>
                    )}
                    {uploadedFiles.length > 0 &&
                        <div
                            className={styles.filesUploadLink}
                            onClick={() => setIsFileUploadPanelOpen(true)}
                        >
                            Uploaded files will be used for context.
                        </div>
                    }
                    <div className={styles.rightAlignedButtons}>
                        <div className={styles.dataSource}>{landingcard[profileConfig.cardIdentifier].title}</div>
                        {/* <ClearChatButton className={styles.commandButton} onClick={clearChat} disabled={!lastQuestionRef.current || isLoading} /> */}
                        {profile === "safegpt2" && (
                            <FileUploadButton
                                className={styles.commandButton}
                                onClick={() => {
                                    pushDataLayerEvent('header navigation links', 'link', 'click', 'Upload Files');
                                    setIsFileUploadPanelOpen(!isFileUploadPanelOpen);
                                    setIsConfigPanelOpen(false);
                                }}
                            />
                        )}
                        <ReleaseNotesButton
                            className={styles.commandButton}
                            onClick={() => {
                                pushDataLayerEvent('header navigation links', 'link', 'click', 'Release Notes', 'https://fonterra.sharepoint.com/sites/GenerativeAIatFonterra/SitePages/Co-op-GPT-Release-Notes(1).aspx?csf=1&web=1&e=3IcLn7&CID=3628efeb-0c91-4af4-a8e6-ff8149999f20');
                                window.open(
                                    "https://fonterra.sharepoint.com/sites/GenerativeAIatFonterra/SitePages/Co-op-GPT-Release-Notes(1).aspx?csf=1&web=1&e=3IcLn7&CID=3628efeb-0c91-4af4-a8e6-ff8149999f20",
                                    "_blank"
                                )
                            }}
                        />
                        <UserGuideButton
                            className={styles.commandButton}
                            onClick={() => {
                                pushDataLayerEvent('header navigation links', 'link', 'click', 'User Guide', 'https://fonterra.sharepoint.com/sites/GenerativeAIatFonterra/SitePages/Co-op-GPT-Training.aspx?csf=1&web=1&e=Vl4lZx&CID=35b76648-6822-4079-b745-b7b354fb01e4');
                                window.open('https://fonterra.sharepoint.com/sites/GenerativeAIatFonterra/SitePages/Co-op-GPT-Training.aspx?csf=1&web=1&e=Vl4lZx&CID=35b76648-6822-4079-b745-b7b354fb01e4', "_blank");
                            }}
                        />
                        <SettingsButton
                            className={styles.commandButton}
                            onClick={() => {
                                pushDataLayerEvent('header navigation links', 'link', 'click', 'AI Settings');
                                setIsConfigPanelOpen(!isConfigPanelOpen);
                                setIsFileUploadPanelOpen(false);
                            }}
                        />
                        {hasAccess && (
                            <AdminButton
                                className={styles.commandButton}
                                onClick={() => {
                                    pushDataLayerEvent('header navigation links', 'link', 'click', 'Admin');
                                }}
                            />
                        )}
                    </div>
                </div>

                <div className={styles.chatRoot}>
                    <div className={styles.chatContainer}>
                        {!lastQuestionRef.current ? (
                            <div className={`${styles.chatEmptyState} ${(!isSidebarVisible || activeAnalysisPanelTab) && styles.chatEmptyStateHiddenSidebar}`}>
                                {/* Fonterra branding dark blue rgb(1, 66, 106)*/}
                                {/* Fonterra branding light blue rgb(114, 191, 68)*/}
                                <ChatBubblesQuestionFilled fontSize={"120px"} primaryFill={themeColors.chatIconFillColor} aria-hidden="true" aria-label="Chat logo" />
                                <h1 className={styles.chatEmptyStateTitle}>Ask a question</h1>
                                {/* <ExampleList onExampleClicked={onExampleClicked} /> */}
                            </div>
                        ) : (
                            <div ref={chatMessageStreamRef}
                                className={`${styles.chatMessageStream} ${(!isSidebarVisible || activeAnalysisPanelTab) && styles.chatMessageStreamHiddenSidebar}`}>
                                {answers.map((answer, index) => (
                                    <div key={`message-${activeConversationIndex}-${index}`} className={styles.chatInteraction}> {/* key to uniquely identify rendering of each conversation */}
                                        <UserChatMessage
                                            message={answer[0]}
                                            index={index}
                                            onMessageEdit={handleEditedMessage}
                                            isLoading={isLoading}
                                            title={document.title}
                                        />
                                        {/* <div className={styles.chatMessageGpt}> */}
                                        <Answer
                                            key={index}
                                            answer={answer[1]}
                                            isSelected={selectedAnswer === index && activeAnalysisPanelTab !== undefined}
                                            onCitationClicked={c => onShowCitation(c, index)}
                                            onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                                            onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)}
                                            onFollowupQuestionClicked={q => makeApiRequest(q, answers)}
                                            onSubmitFeedback={handleFeedbackSubmit}
                                            responseID={answer[1].responseID || ''}
                                            activeAnalysisPanelTab={activeAnalysisPanelTab}
                                        />
                                        {/* </div> */}
                                    </div>
                                ))}
                                {isLoading && (
                                    <div key={`message-${activeConversationIndex}-${answers.length}`}> {/* key to uniquely identify rendering of each conversation */}
                                        <UserChatMessage
                                            message={lastQuestionRef.current}
                                            index={answers.length}
                                            onMessageEdit={handleEditedMessage}
                                            isLoading={isLoading}
                                            title={document.title}
                                        />
                                        {/* <div className={styles.chatMessageGptMinWidth}> */}
                                        <AnswerLoading />
                                        {/* </div> */}
                                    </div>
                                )}
                                {(error && activeConversationIndex == erroredConversation) ? (
                                    <div key={`message-${activeConversationIndex}-${answers.length}`}> {/* key to uniquely identify rendering of each conversation */}
                                        <UserChatMessage
                                            message={lastQuestionRef.current}
                                            index={answers.length}
                                            onMessageEdit={handleEditedMessage}
                                            isLoading={isLoading}
                                            title={document.title}
                                        />
                                        {/* <div className={styles.chatMessageGptMinWidth}> */}
                                        <AnswerError error={error.toString()} error_code={answers.length > 0 ? answers[answers.length - 1][1].error_code : ""} onRetry={() => makeApiRequest(lastQuestionRef.current, answers)} />
                                        {/* </div> */}
                                    </div>
                                ) : null}
                                <div ref={chatMessageStreamEnd} />
                            </div>
                        )}
                        {!lastQuestionRef.current ? (
                            <div className={`${styles.chatMessageStream} ${(!isSidebarVisible || activeAnalysisPanelTab) && styles.chatMessageStreamHiddenSidebar}`}>
                                <>
                                    <div className={styles.chatMessageGptMinWidth}>
                                        <Greeting message={initialGreeting} />
                                    </div>
                                </>
                                <div className={`${styles.chatInputEmpty} ${(!isSidebarVisible || activeAnalysisPanelTab) ? styles.chatInputEmptyHiddenSidebar : ''}`}>
                                    <QuestionInput
                                        question={inputText}
                                        setQuestion={setInputText}
                                        clearOnSend
                                        placeholder={maxInteractionsReached ? "Maximum interactions reached, please start a new chat" : "Enter a message"}
                                        disabled={isLoading || maxInteractionsReached || isIndexing}
                                        isLoading={isLoading}
                                        onSend={question => makeApiRequest(question, answers)}
                                        uploadedFilesPresent={uploadedFiles}
                                        conversationID={conversationID}
                                        onFileUploadLinkClick={onFileUploadLinkClick}
                                        onStopThinking={handleStopThinking}
                                        ref={questionInputRef}
                                        profile={profile}  
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className={`${styles.chatMessageStream} ${(!isSidebarVisible || activeAnalysisPanelTab) && styles.chatMessageStreamHiddenSidebar}`}>
                                <div className={`${styles.chatInputEmpty} ${(!isSidebarVisible || activeAnalysisPanelTab) ? styles.chatInputEmptyHiddenSidebar : ''}`}>
                                    <QuestionInput
                                        question={inputText}
                                        setQuestion={setInputText}
                                        clearOnSend
                                        placeholder={maxInteractionsReached ? "Maximum interactions reached, please start a new chat" : "Enter a message"}
                                        disabled={isLoading || maxInteractionsReached || isIndexing}
                                        isLoading={isLoading}
                                        onSend={question => makeApiRequest(question, answers)}
                                        uploadedFilesPresent={uploadedFiles}
                                        conversationID={conversationID}
                                        onFileUploadLinkClick={onFileUploadLinkClick}
                                        onStopThinking={handleStopThinking}
                                        ref={questionInputRef}
                                        profile={profile} 
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    {answers.length > 0 && activeAnalysisPanelTab && (
                        <AnalysisPanel
                            className={styles.chatAnalysisPanel}
                            activeCitation={activeCitation}
                            onActiveTabChanged={x => onToggleTab(x, selectedAnswer)}
                            citationHeight="810px"
                            answer={answers[selectedAnswer][1]}
                            activeTab={activeAnalysisPanelTab}
                        />
                    )}

                    <Panel
                        headerText="Configure answer generation"
                        isOpen={isConfigPanelOpen}
                        isBlocking={false}
                        isLightDismiss={true}
                        onDismiss={() => setIsConfigPanelOpen(false)}
                        closeButtonAriaLabel="Close"
                        onRenderFooterContent={() => <DefaultButton style={closeButtonStyle} className={styles.closeButton} onClick={() => setIsConfigPanelOpen(false)}>Close</DefaultButton>}
                        isFooterAtBottom={true}
                        styles={panelStyles}
                    >
                        <Slider className={styles.slider} label={getTemperatureDescription(temperature)} min={0} max={1.0} step={0.1} defaultValue={DefaultTemperature} value={temperature} showValue onChange={onTemperatureChange} styles={sliderStyles} />
                        {(profile === "safegpt2") &&
                            <Toggle
                                className={styles.chatSettingsSeparator}
                                defaultChecked={useWebSearch}
                                checked={useWebSearch}
                                onText="Use Web Search"
                                offText="Web Search Off"
                                onChange={onUseWebSearchChange}
                                styles={toggleStyles}
                            />
                        }
                        {(profile === "coopgptppl" || profile === "coopgptppl2") && <SpinButton
                            className={styles.chatSettingsSeparator}
                            label="Number of search results to be retrieved: (min: 50, max: 100)"
                            min={50}
                            max={100}
                            defaultValue={retrieveCount.toString()}
                            onChange={onRetrieveCountChange}
                            styles={spinButtonStyles}
                        />}
                        <br />
                        {showPreviewFeatures && <Toggle
                            className={styles.chatSettingsSeparator}
                            defaultChecked={false}
                            checked={advancedSettings}
                            onText="Advanced Settings"
                            offText="Basic Settings"
                            onChange={onAdvancedSettingsChange}
                            styles={toggleStyles}
                        />}
                        <br />
                        {advancedSettings && (
                            <>
                                {/* <Toggle
                                    className={`${styles.chatSettingsSeparator} && ${llmModelName == "AzureOpenAI"}`}
                                    defaultChecked={true}
                                    checked={gptVersion}
                                    onText="Gpt4"
                                    offText="Gpt4 Turbo"
                                    onChange={onGptVersionChange}
                                    styles={toggleStyles}
                                /> */}
                                <Dropdown
                                    // className={styles.dropdown}
                                    label="GPT Model Version"
                                    options={[
                                        { key: 1, text: getGPTLabel(1) },
                                        { key: 2, text: getGPTLabel(2) },
                                        { key: 3, text: getGPTLabel(3) },
                                        { key: 4, text: getGPTLabel(4) }
                                    ]}
                                    defaultSelectedKey={gptModelKey}
                                    selectedKey={gptModelKey ? gptModelKey : getGptModelKey(DefaultGPTVersion)}
                                    onChange={(_, option) => onGptModelKeyChange(option?.key as number)}
                                    styles={dropdownStyles}
                                />
                                {(profile === "coopgptppl" || profile === "coopgptppl2") && <Toggle
                                    className={styles.chatSettingsSeparator}
                                    defaultChecked={false}
                                    checked={profile != DefaultProfile}
                                    onText="PPL Langchain"
                                    offText="PPL w/o Langchain"
                                    onChange={onProfileChange}
                                    styles={toggleStyles}
                                />}
                                {(profile === "coopgptppl" || profile === "coopgptppl2") && (<Toggle
                                    className={styles.chatSettingsSeparator}
                                    defaultChecked={true}
                                    checked={nativeSearch}
                                    onText="Native Search"
                                    offText="Enterprise Search"
                                    onChange={onNativeSearchChange}
                                    styles={toggleStyles}
                                />)}
                                {(profile === "coopgptppl" || profile === "coopgptppl2") && nativeSearch && (
                                    <Toggle
                                        className={styles.chatSettingsSeparator}
                                        defaultChecked={useSemanticRanker}
                                        checked={useSemanticRanker}
                                        onText="Use semantic search"
                                        offText="Use basic search"
                                        onChange={onUseSemanticRankerChange}
                                        styles={toggleStyles}
                                    />
                                )}
                                {(profile === "coopgptppl" || profile === "coopgptppl2") && <TextField
                                    className={styles.chatSettingsSeparator}
                                    defaultValue={promptTemplate}
                                    label="Override default prompt"
                                    multiline
                                    autoAdjustHeight
                                    onChange={onPromptTemplateChange}
                                    description="Text here replaces the default Prompt. Include '{sources}' to query the connected data. To add your text to the default prompt prefix it with '>>>'"
                                    autoComplete="on"
                                    styles={textFieldStyles}
                                />}
                            </>
                        )}
                        <Dropdown
                          id="tone-dropdown-1"
                          label="Tone Setting Options"
                          options={toneDropdownOptions}
                          selectedKey={selectedToneOptionKey}
                          onChange={onToneOptionChange}
                          styles={dropdownStyles}
                        />
                        <Dropdown
                          id="tone-dropdown-2"
                          label="Detail of Response"
                          options={detailDropdownOptions}
                          selectedKey={selectedDetailOptionKey}
                          onChange={onDetailOptionChange}
                          styles={dropdownStyles}
                        />
                    </Panel>
                    <Panel
                        headerText="Upload files of the same type to be used as context"
                        isOpen={isFileUploadPanelOpen}
                        isBlocking={false}
                        isLightDismiss={true}
                        onDismiss={() => setIsFileUploadPanelOpen(false)}
                        closeButtonAriaLabel="Close"
                        onRenderFooterContent={() => <DefaultButton style={closeButtonStyle} className={styles.closeButton} onClick={() => setIsFileUploadPanelOpen(false)}>Close</DefaultButton>}
                        isFooterAtBottom={true}
                        layerProps={{ eventBubblingEnabled: true }}
                        styles={panelStyles}
                        className={styles.myCustomPanel}
                    >
                        <FileUploadComponent
                            loading={isLoading}
                            onUpload={onFileUpload}
                            conversationId={allConversations.length > 0 && allConversations[activeConversationIndex]
                                ? allConversations[activeConversationIndex].conversationID || uuidv4()
                                : uuidv4()}
                            onIndexingComplete={handleIndexingComplete}
                            profile={profile}
                            onIndexDeletion={handleIndexDeletion}
                            onClearFiles={handleClearFiles}
                            clearUploadSessionId={clearUploadSessionId}
                        />
                    </Panel>
                </div>
                {(profile !== "safegpt2") && (lastQuestionRef.current && !isLoading) &&
                    <h3 className={`${styles.footer} ${(!isSidebarVisible || activeAnalysisPanelTab) && styles.footerHiddenSidebar}`}> Disclaimer: Answers are retrieved using only the data sources that you have access to. There may be more information in sources which you do not have access to.</h3>
                }
            </div>
            <TwoButtonDialog
                ref={confirmDialogRef}
                title="Delete Confirmation"
                message={conversationsToDelete.length > 0 
                    ? `Are you sure you want to delete these ${conversationsToDelete.length} conversations?`
                    : "Are you sure you want to delete this conversation?"}
                callback={handleDeleteConversation}
            />
            <ErrorDialog
                ref={errorDialogRef}
                title="Memory Limit Reached"
                message="The local memory limit has been reached. Please delete a chat to free up memory."
            />
            <ErrorDialog
                ref={genericErrorDialogRef}
                title="Error"
                message="There was an error. Please refresh the page."
            />
            <TwoButtonDialog
                ref={versionDialogRef}
                title="Update Required"
                message="Your version of Co-op GPT is out of date, please refresh the page to get the latest version. Don't worry, your saved chats will still be available, but you will not be able to use Co-op GPT until you refresh."
                callback={handleVersionUpdateRequired}
                cancelText="Give me a sec"
                confirmText="Refresh Now"
            />
            <TwoButtonDialog
                ref={timeoutDialogRef}
                title="Connection Timeout"
                message="It is taking too long to generate a response. Your current chat may be too long, resulting in long processing times, please start a new chat to continue using Co-op GPT."
                callback={handleNewChatRequired}
                cancelText="Cancel"
                confirmText="Start New Chat"
            />
        </div>
    );
};

export default Chat;